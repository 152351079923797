<template>
  <div class="p_item">
    <div id="c_ticket" class="c_ticket" :style="{ clipPath: `path('${path}')` }">
      <div class="doneMask" :class="{'canuse':status === 'getCoupon'}"></div>
      <div class="mark" :class="{ done: status ==='redeemed', invalid: status ==='counting' }">
      </div>
      <div class="img_wrapper">
        <img :src="data.imgSrc ? data.imgSrc : imgSrc" alt="" />
      </div>
      <div
        @click="
          redirect({
            path: 'news',
            query: {
              id: data.tNo,
              type: data.type,
              status: status,
              s_date_receive: data.s_date_receive,
              e_date_receive: data.e_date_receive,
              startDate: data.startDate,
              endDate: data.endDate,
              title: data.title,
              sub: data.sub,
              sub2: data.sub2,
              note: data.note,
              content: data.content,
              timing: data.timing,
              pcid: data.pcid,
            },
          })
        "
        class="detail_wrapper"
        :style="{height: `${cardHeight}px`}"
      >
        <div>
          <h5 class="body-medium c_title" style="color: #0d0c0c">{{ data.title }}</h5>
          <div class="due">
            <p>兌換期限 :</p>
            {{
              [data.startDate.slice(0, 4), data.startDate.slice(4, 6), data.startDate.slice(6, 8)].join('/')
            }}-
            {{
              [data.endDate.slice(0, 4), data.endDate.slice(4, 6), data.endDate.slice(6, 8)].join('/')
            }}
          </div>
        </div>
        <div class="more_btn">
          <p>查看更多</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCouponImage, SendCouponToUser } from '@/apis/api.js'

export default {
  name: 'CouponItem',
  props: {
    data: {
      type: Object,
    },
    status: {
      type: String,
    },
    valid: {
      type: String,
    },
  },
  data: () => ({
    cardHeight: 0,
    imgSrc: '',
    path: '',
  }),
  created() {
    var parent = this
    getCouponImage(parent.data.tNo).then(function (image_url) {
      parent.imgSrc = image_url
    })
    if (parent.$route.query.tNo == this.data.tNo) {
      this.redirect({
        path: 'news',
        query: {
          id: this.data.tNo,
          type: this.data.type,
          status: this.status,
          startDate: this.data.startDate,
          endDate: this.data.endDate,
          title: this.data.title,
          sub: this.data.sub,
          timing: this.data.timing,
          pcid: this.data.pcid,
        },
      })
    }
  },
  mounted() {
    this.svgResize()
    window.addEventListener('resize', this.svgResize)
  },
  methods: {
    svgResize() {
      let parent = this
      const c_ticket = document.querySelector('.c_ticket')
      const rect = c_ticket.getBoundingClientRect()
      const width = rect.width
      const height = rect.height
      const radius = 7
      this.cardHeight = height

      parent.path = `M0 0 L${width / 2 - 7} 0 A${radius} ${radius} 0 0 0 ${width / 2 + radius / 2} ${radius} A${radius} ${radius} 0 0 0 ${width / 2 + 7} 0 L${width} 0 L${width} ${height} L${width / 2 + 7} ${height} A${radius} ${radius} 0 0 0 ${width / 2 - radius / 2} ${height - radius} A${radius} ${radius} 0 0 0 ${width / 2 - 7} ${height} L0 ${height} Z`
    },
    handleSave() {
      this.$emit('click:save')
    },
    redirect(link) {
      var parent = this
      parent.sendToUser()
      if (this.valid != 'expired') {
        setTimeout(() => {
          parent.$router.push(link)
        }, 200)
      }
    },
    sendToUser() {
      SendCouponToUser(
        localStorage.getItem('phone'),
        localStorage.getItem('password'),
        this.data.tNo
      ).then(function(rsp) {
        console.log('回傳訊息',JSON.parse(rsp).Message);
      })
    },
    getImage() {
      const parent = this
      getCouponImage(this.data.tNo).then(function (image_url) {
        if (!image_url) {
          setTimeout(function () {
            parent.getImage()
          }, 1000)
        } else {
          parent.imgSrc = image_url
        }
      })
    },
  },
  watch: {
    data() {
      this.getImage()
    },
  },
}
</script>

<style lang="scss" scoped>
.canuse{
  pointer-events: none !important;
  opacity: 0 !important;
}
.p_item {
  position: relative;
  display: flex;
  border-radius: 18px;
  margin-bottom: 16px;
  aspect-ratio: 312/121;
  // width: 100%;
  // height: 121px;
  transition: background 0.3s;
  filter: drop-shadow(-1px 1px 4px #4b25187a);
  .c_ticket {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fffbf5;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    .doneMask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0.4;
      background-color: #fff;
      pointer-events: none;
    }
    .mark{
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-40%);
      z-index: 3;
      width: 85px;
      height: 60px;
      &.done {
        background-image: url('~@/assets/images/ic_ticket.svg');
        background-repeat: no-repeat;
        background-position: right 0px top 5px;
        .due {
          background: none;
        }
      }
      &.invalid {
        background-image: url('~@/assets/images/ic_ticket_expired.svg');
        background-repeat: no-repeat;
        background-position: right 0px top 5px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .img_wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .detail_wrapper {
      display: flex;
      text-align: left;
      padding: 14px 14px 8px 12px;
      flex-direction: column;
      justify-content: space-between;
      h5 {
        margin-bottom: 6px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      h6 {
        color: $color_main_grey_dark;
        font-size: 16px;
        margin-bottom: 4px;
      }
      .more_btn {
        display: flex;
        justify-content: flex-end;
        & p {
          border-radius: 8px;
          padding: 6px 8px;
          background-color: #ff9f4b;
          color: white;
          font-family: Noto Sans TC !important;
          font-size: 10px !important;
          line-height: 12px !important;
          letter-spacing: 0.4px !important;
          font-weight: 700 !important;
          box-shadow: -0.52px 2.61px 5.22px -1.04px #f1913d14,
            -0.52px 0.52px 2.09px -1.04px #f1913d1f;
          &:active {
            background-color: #D16200;
          }
        }
      }
      .due {
        color: $color_main_grey;
        font-family: Noto Sans TC !important;
        font-size: 10px !important;
        line-height: 12px !important;
        letter-spacing: 0.4px !important;
        font-weight: 400 !important;
        margin-bottom: 6px;
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }
  }

  &:active {
    background: #e9e0da;
  }
}
</style>
