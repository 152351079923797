var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p_item"},[_c('div',{staticClass:"c_ticket",style:({ clipPath: `path('${_vm.path}')` }),attrs:{"id":"c_ticket"}},[_c('div',{staticClass:"doneMask",class:{'canuse':_vm.status === 'getCoupon'}}),_c('div',{staticClass:"mark",class:{ done: _vm.status ==='redeemed', invalid: _vm.status ==='counting' }}),_c('div',{staticClass:"img_wrapper"},[_c('img',{attrs:{"src":_vm.data.imgSrc ? _vm.data.imgSrc : _vm.imgSrc,"alt":""}})]),_c('div',{staticClass:"detail_wrapper",style:({height: `${_vm.cardHeight}px`}),on:{"click":function($event){return _vm.redirect({
          path: 'news',
          query: {
            id: _vm.data.tNo,
            type: _vm.data.type,
            status: _vm.status,
            s_date_receive: _vm.data.s_date_receive,
            e_date_receive: _vm.data.e_date_receive,
            startDate: _vm.data.startDate,
            endDate: _vm.data.endDate,
            title: _vm.data.title,
            sub: _vm.data.sub,
            sub2: _vm.data.sub2,
            note: _vm.data.note,
            content: _vm.data.content,
            timing: _vm.data.timing,
            pcid: _vm.data.pcid,
          },
        })}}},[_c('div',[_c('h5',{staticClass:"body-medium c_title",staticStyle:{"color":"#0d0c0c"}},[_vm._v(_vm._s(_vm.data.title))]),_c('div',{staticClass:"due"},[_c('p',[_vm._v("兌換期限 :")]),_vm._v(" "+_vm._s([_vm.data.startDate.slice(0, 4), _vm.data.startDate.slice(4, 6), _vm.data.startDate.slice(6, 8)].join('/'))+"- "+_vm._s([_vm.data.endDate.slice(0, 4), _vm.data.endDate.slice(4, 6), _vm.data.endDate.slice(6, 8)].join('/'))+" ")])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more_btn"},[_c('p',[_vm._v("查看更多")])])
}]

export { render, staticRenderFns }